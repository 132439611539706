// Copyright 2022 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import Casdoor from 'casdoor-vue-sdk';
import { createApp } from 'vue';
import App from './App.vue';
import router from './route/index';

const config = {
  serverUrl: "http://localhost:5000/api",
};

const app = createApp(App)
app.use(Casdoor, config)
app.use(router)
app.mount('#app')
